.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your CSS file */
.loader {
  border-top-color: #ffffff; /* Белый цвет для верхней границы */
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;

}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.responsive-div {
  left: 12px;
  top: -144px;
}

@media (max-width: 1200px) {
  .responsive-div {
    left: 10px;
    top: -120px;
  }
}

@media (max-width: 992px) {
  .responsive-div {
    left: 8px;
    top: -120px;
  }
}

@media (max-width: 768px) {
  .responsive-div {
    left: 6px;
    top: -72px;
  }
}

@media (max-width: 576px) {
  .responsive-div {
    left: 4px;
    top: -48px;
  }
}

