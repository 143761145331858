@import './fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'sans-serif';

}
.product-carousel .carousel .slide {
  background: transparent;
}
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(102, 102, 102);
  border-radius: 25%;
  cursor: pointer;
}

.custom-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: rgb(102, 102, 102);
  border-radius: 25%;
  cursor: pointer;
}
.product-carousel .carousel .slide img {
  width: auto;
  height: 200px;
  object-fit: contain;
}

.product-carousel-container {
  width: 100%;
  overflow: hidden;
}

.product-card {
  width: 270px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-info {
  background-color: rgb(235, 236, 236); /* Light gray background */
  padding: 0.5rem;
  flex-grow: 1;
}

.product-info span {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.product-info span:first-child {
  font-weight: 600;
}

.product-info span:last-child {
  color: #6b7280; /* Gray color for the article number */
}

.product-track {
  display: flex;
  animation: scroll 10s linear infinite;
}

.product-slide {
  flex: 0 0 20%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.product-carousel-container:hover .product-track {
  animation-play-state: paused;
}
.custom-carousel .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  background: #000;
  border: none;
  box-shadow: none;
  opacity: 0.5;
}

.custom-carousel .carousel .control-dots .dot.selected {
  opacity: 1;
}

.slick-dots {
  bottom: 14px;
}

.slick-dots li button:before {
  font-size: 14px;
  color: white;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: black;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}
.grid-auto-flow-dense {
  grid-auto-flow: dense;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-list {
  overflow: hidden;
}

.slick-slide img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}


/* Add this to your CSS file */
.loader {
  border-top-color: #ffffff; /* Белый цвет для верхней границы */
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* src/index.css */
/* Добавьте Tailwind CSS директивы */
@tailwind base;
@tailwind components;
@tailwind utilities;

