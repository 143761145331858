@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/Open_Sans (1)/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/Open_Sans (1)/static/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}